
import { Navbar } from "./Navbar";

export const Header = () => {
    return (
        <div>
            <Navbar />
        </div>
       
    )
}